_PE.modules.singleVisitLog = {
	id: "singleVisitLog",
	PEClass: ".single-visit-log",
	elements: null,

	init: function () {

		var mod = this;

		var id = mod.elements.data('id');

		_PE.core.ajax.send( { url: window.pe_parameters.wp_ajax_url, data: { 'action' : 'single_visit_log', 'id' : id }, success: mod.ajax_success } );

	},

	ajax_success: function( msg ) {
		_PE.utils.trace( msg );
	}

};

